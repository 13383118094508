<template>
  <v-dialog persistent  v-model="toggle" width="500">
    <v-card >
      <v-app-bar class="" color="blue" dark dense>
        <div class="dialog-recover-password-titulo-text">
          <v-toolbar-title>Recuperão De Senha</v-toolbar-title>
        </div>
      </v-app-bar>
      <p class="card-login-texto">Enviaremos um email de recuperação.</p>
      <v-form>
        <v-card-text>
          <v-text-field 
            label="Username" 
            prepend-icon="mdi-account-circle"
            v-model="username"
            height="30px"
            dense
            :error-messages="usernameErrors"
            @input="$v.username.$touch()" 
            @blur="$v.username.$touch()" 
            />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn type="submit" color="info" @click.prevent="sendRecoverPasswordEmail" class="login-btn" tile :loading="loading">Enviar email de recuperação</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

export default {
  name:'RecoverPassword',
  data: () => ({
    toggle: false,
    username: '',
    loading: false,
    snackbar: {
      toggle: false,
      type: null,
      msg: null
    },
  }),
  mounted () {  //É como um observer, esta sempre na escuta
    this.openRecoverPasswordModal()
  },
  computed: { //É atualizado sempre que o estado da loja muda
    usernameErrors () {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required && errors.push('Username é obrigatório.')
      return errors
    }
  },
  methods: {
    ...mapActions('auth', ['recoverPassword']),
    sendRecoverPasswordEmail () {
      const self = this
      this.loading = true

      if (this.$v.username.$invalid) { 
          this.$v.username.$touch()
          return false 
      }

      self.recoverPassword({username: self.username}).then((res) => {
        this.loading = false
        this.toggle = false
        successSnackbar(res.data.message)
        return true
      }).catch(e => {
        this.loading = false
        errorSnackbar(e)
        return false
      })
    },

    openRecoverPasswordModal () {
      Events.$on('auth::openModalRecoverPassword', () => {
        this.toggle = true
      })
    }
  },
  
  validations () {
    return {
      username: {required}
    }
  }
}
</script>

<style lang="scss" scoped src="../../../assets/styles/auth.scss"></style>